import logo from './logo.svg';
import './App.css';
import Header from '../src/components/Header';
import Counter from '../src/components/Counter';
import Avatar from '../src/components/Avatar';



function App() {
  return (
    <>
   <Header />
  <main>
    <section className="d-flex align-items-end text-white py-lg-5 p-0 section1">
      <div className="container">
        <div className="row align-items-end pt-5 pb-4 border-bottom">
          <div className="col-1">
            <a
              className="btn btn-outline-light rounded-circle btn-purple"
              href="#content"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136   c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002   v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864   c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872   l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />{" "}
                  </g>
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </div>
          <div className="col-lg-7 col-12 px-5">
            <h3 className="text-uppercase h2 mb-0">
            EMU where the boundaries between reality and the digital world blur.
            </h3>
          </div>
          <div className="col-lg-4 col-12">
            <div className="d-flex align-items-end justify-content-lg-end justify-content-center mt-lg-0 mt-4">
              <a href="https://www.xbox.com/" target="_blank" className="me-3">
                <img
                  src="./assets/img/XBOX.svg"
                  alt="Xbox"
                  width={132}
                  height={40}
                />
              </a>
              <a href="https://www.playstation.com/" target="_blank">
                <img
                  src="./assets/img/PlayStation.svg"
                  alt="PlayStation"
                  width={157}
                  height={32}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="d-flex align-items-center text-white big-titles pt-lg-0 pt-4 pb-5 section2">
      <div className="container text-left">
        <h1 className="display-1 fw-bold text-uppercase">
          A New Era of 
        </h1>
        <h1 className=" custom-colors display-1 fw-bold text-gradient text-uppercase">
        Digital Exploration
        </h1>
      </div>
    </section>
    <section className="bg-dark text-white">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div className="ratio ratio-16x9">
              <video
                className="w-100"
                src="assets/Pexels-Videos-2759479.mp4" autoPlay muted
                controlsList="nodownload"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="background-dark text-white py-lg-5 py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 col-12 px-lg-5 px-4">
            <h2 className="fw-bold h1 text-uppercase w-lg-75 w-100">
              Subscribe for the exclusive updates!
            </h2>
          </div>
          <div className="col-md-6">
            <form className=" row align-items-center">
              <div className="col-md-8 px-lg-0 px-4">
                <div className="form-group me-2 flex-grow-1">
                  <input
                    type="email"
                    className="border-only-end background-border-box w-100 text-white "
                    placeholder="Enter Your Email Address"
                    required=""
                  />
                </div>
                <div className="form-group mt-1 me-2 mt-3">
                  <input type="checkbox" id="privacyPolicy" required="" />
                  <label htmlFor="privacyPolicy" className="form-check-label">
                    I agree to the{" "}
                    <a
                      href="/privacy-policy/"
                      target="_blank"
                      className="text-decoration-none text-white"
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
              </div>
              <div className="col-md-4 text-end ">
                <button type="submit" className=" btn text-white">
                  <i className=" fas fa-paper-plane mt-3" /> Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section className="text-white py-lg-5 py-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <img
              src="./assets/img/image-faq-copyright-purh44ms4p5cl4kcp0dxc8016fb2lvi5d2ar9t412c.jpg"
              className="img-fluid"
              alt="Modern Ideas"
            />
          </div>
          <div className="col-md-7">
            <span className="text-uppercase">The EMU Metaverse</span>
            <h1 className="fw-bold h1 text-uppercase mt-3">
            Explore a Limitless Digital Universe
            </h1>
            <p className="mt-4 text-unique-grey">
            Dive into the EMU Metaverse, a boundless digital world where creativity and adventure await at every corner. Discover new realms, embark on epic quests, and immerse yourself in stunning environments.
            </p>
            <a
              href="https://gamic.themerex.net/our-services/"
              className="text-decoration-none text-white "
            >
              <span className="underline_anim underline_do_hover border-bottom pb-2">
                Read More
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="position-relative text-white py-4">
      <div className="w-100 text-center">
        <div className="marquee">
          <span className="display-1 fw-bold text-purple">
            OUR GAMES USE ONLY THE BEST
          </span>
          <span className="display-1 fw-bold text-purple">
            OUR GAMES USE ONLY THE BEST
          </span>
          <span className="display-1 fw-bold text-purple">
            OUR GAMES USE ONLY THE BEST
          </span>
        </div>
      </div>
    </section>
    <section className=" text-white py-lg-5 py-0">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-3">
            <h3 className="h6">People</h3>
            <p className="display-4 fw-bold">
            <Counter end={90} duration={100} />+</p>
          </div>
          <div className="col-md-3">
            <h3 className="h6">Coffee Cups</h3>
            <p className="display-4 fw-bold"><Counter end={2548} duration={1000} /></p>
          </div>
          <div className="col-md-3">
            <h3 className="h6">Projects</h3>
            <p className="display-4 fw-bold"><Counter end={25} duration={1000} />+</p>
          </div>
          <div className="col-md-3">
            <h3 className="h6">Partners</h3>
            <p className="display-4 fw-bold"><Counter end={256} duration={1000} /></p>
          </div>
        </div>
      </div>
    </section>
    <section className="text-white my-4 py-4">
      <div className="row g-0 align-items-center">
        <div className="col-md-7">
          <img
            src="./assets/img/post42-copyright-890x664.jpg"
            className="img-fluid w-100"
            alt="Graphics"
          />
        </div>
        <div className="col-md-5 p-lg-5 p-3">
          <h4 className="text-uppercase h6">NFT Marketplace</h4>
          <h1 className="fw-bold text-uppercase mt-2">
          Discover Unique Digital Assets
          </h1>
          <p className="mt-4 text-unique-grey">
          Explore the EMU NFT Marketplace, a hub for unique and rare digital assets. From art and collectibles to in-game items, find and acquire NFTs that enhance your virtual experience.
          </p>
          <p>
          Engage in a dynamic marketplace where you can buy, sell, and trade NFTs with other users. Enjoy secure transactions powered by blockchain technology, ensuring transparency and trust.
          </p>
          <a
            href="https://gamic.themerex.net/whitepaper/"
            className="text-white btn btn-lg btn-cs mt-4 px-5 py-3 text-uppercase"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
    <section className="text-white py-lg-5 py-0">
      <div className="container text-center">
        <h3 className="mb-5">Partners</h3>
        <div className="row justify-content-center mb-5">
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/parnter.webp"
              className="img-fluid"
              alt="Partner 1"
            />
          </div>
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/partner2.webp"
              className="img-fluid"
              alt="Partner 2"
            />
          </div>
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/partner3.webp"
              className="img-fluid"
              alt="Partner 3"
            />
          </div>
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/partner4.webp"
              className="img-fluid"
              alt="Partner 4"
            />
          </div>
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/partner5.webp"
              className="img-fluid"
              alt="Partner 5"
            />
          </div>
          <div className="col-6 col-md-2 mb-4">
            <img
              src="/assets/img/partner.webp"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="text-light">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="mb-5" />
            <div className="animation_type_block">
              <h3 className="text-uppercase h6 fw-normal">Virtual Shopping in EMU</h3>
              <h1 className="display-4 fw-bold text-uppercase">
              Experience Next-Level<br /> Virtual Shopping in EMU
              </h1>
              <a
                href="https://gamic.themerex.net/about-us/"
                className="btn btn-primary btn-lg btn-cs py-3 px-5 text-uppercase mt-4"
              >
                More Information
              </a>
            </div>
            <div className="mb-4" />
            <div className="mb-5" />
          </div>
        </div>
      </div>
    </section>
    <section className="position-relative text-center video-section mb-5 section3">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 p-0">
            <a
              href="#go-video4"
              className="play-button position-absolute top-50 start-50 translate-middle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={64}
                viewBox="0 0 12.24 16.583"
              >
                <path
                  d="M17.57,7.966,6.119.07A.395.395,0,0,0,5.5.394V16.188a.395.395,0,0,0,.619.325l11.451-7.9a.395.395,0,0,0,0-.65Z"
                  transform="translate(-5.5 0)"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="position-relative text-white pt-5">
      <div className="translate-middle w-100 text-center">
        <div className="marquee">
          <span className="display-1 fw-bold text-big-color">
            OUR GAMES USE ONLY THE BEST
          </span>
          <span className="display-1 fw-bold text-big-color">
            OUR GAMES USE ONLY THE BEST
          </span>
          <span className="display-1 fw-bold text-big-color">
            OUR GAMES USE ONLY THE BEST
          </span>
        </div>
      </div>
    </section>
    <section className="d-flex text-white align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 position-relative">
            <img
              src="./assets/img/image-about-copyright-purh44mtad0humm89hkg5z6oq41vbz7slcf8f4egsm.jpg"
              className="img-fluid"
              alt="image-about-copyright"
            />
            <div className="position-absolute text-white purple-bg image-overlap-text">
              <h3 className="text-uppercase h2">We make fast, modern games</h3>
              <a
                href="#"
                className="btn-outer d-inline-block float-end bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 4.646a.5.5 0 0 1 0 .708L8.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.5 8a.5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </a>
            </div>
            <div className="position-absolute text-white d-flex align-items-center justify-content-center"></div>
          </div>
          <div className="col-md-5 py-lg-0 py-4 text-white">
            <h2 className="mb-4 h6">EMU</h2>
            <h1 className="mb-4 h1">Debit Card</h1>
            <p className="text-unique-grey">
            The EMU Debit Card seamlessly integrates with EMU COIN, the native currency of the EMU metaverse. It allows users to spend their EMU COIN both within the metaverse and in the real world, bridging the gap between virtual and physical economies.
            </p>
            {/* <div className="mt-5">
              <h6 className="d-inline-block">
                <a href="#" className="text-white text-decoration-none">
                  GAME OF THE YEAR
                </a>
              </h6>
              <h6 className="d-inline-block float-end">
                <a
                  href="#"
                  className="text-white text-decoration-none float-end"
                >
                  2019
                </a>
              </h6>
              <p className="text-dark-grey">Graphic &amp; Animation</p>
              <p className="text-unique-grey w-75">
                Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                sit.
              </p>
            </div> */}
            {/* <div className="mt-5">
              <h6 className="d-inline-block">
                <a href="#" className="text-white text-decoration-none">
                  BEST TEAM PLAYERS
                </a>
              </h6>
              <h6 className="d-inline-block float-end">
                <a href="#" className="text-white text-decoration-none">
                  2021
                </a>
              </h6>
              <p className="text-dark-grey">New Strategy</p>
              <p className="text-unique-grey w-75">
                Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                sit.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
    <section class="out_team py-5" id="our_team">
        <div class="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-lg-5 mb-0" />
              <div className="animation_type_block">
                <h3 className="text-uppercase text-unique-grey h6 fw-normal">
                  Meet Our
                </h3>
                <h1 className="display-4 text-white fw-bold text-uppercase">
                  Team
                </h1>
              </div>
            </div>
          </div>
          <div class="row justify-content-evenly mt-5">
           
              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                  <div class="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                      <h4 className="h4 text-light">Jack</h4>
                      <p class="my-4 text-unique-grey">CTO</p>
                      <Avatar size="50%" src="assets/img/office-man.png"/>
                  </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                  <div class="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                  <h4 className="h4 text-light">Jason Roy</h4>
                      <p class="my-4 text-unique-grey">MD</p>
                      <Avatar size="50%" src="assets/img/office-man.png"/>
                  </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                  <div class="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                    <h4 className="h4 text-light">Marsh</h4>
                      <p class="my-4 text-unique-grey">COD</p>
                      <Avatar size="50%" src="assets/img/office-man.png"/>
                  </div>
              </div>
          </div>
        </div>
    </section>

    <section className="roadmap pt-5 text-center">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="mb-lg-5 mb-0" />
            <div className="animation_type_block">
              <h3 className="text-uppercase text-unique-grey h6 fw-normal">
                See Our
              </h3>
              <h1 className="display-4 text-white fw-bold text-uppercase">
                Roadmap
              </h1>
            </div>
          </div>
        </div>
        <img className="img-fluid" src="./assets/img/roadmap.png" />
      </div>
    </section>
    <section className="swap-ui text-white">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-5 p-lg-5">
            <h4 className="text-uppercase h6">What we do</h4>
            <h1 className="fw-bold text-uppercase mt-2">
              Top graphics and premium digital experiences
            </h1>
            <p className="mt-4 text-unique-grey">
              Adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
            </p>
            <p>
              Wiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim.
            </p>
            <a
              href="https://gamic.themerex.net/whitepaper/"
              className="text-white btn btn-lg btn-cs mt-4 px-5 py-3 text-uppercase"
            >
              Learn More
            </a>
          </div>
          <div className="col-md-7 mt-lg-0 mt-5">
            <div className="d-flex justify-content-center">
              <ul className="nav nav-tabs border-0 border-radius-0 mb-3">
                <li className="nav-item me-3">
                  <a className="nav-link active bg-purple-shade" href="#">
                    Buy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link bg-purple-shade " href="#">
                    Sell
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-center pt-5">
              <div className="exchange-card p-4">
                <div className="swap-header">
                  <h4 className="mb-3 d-inline-block">Exchange</h4>
                  <a className="mb-3 mt-2 h6 text-dark-grey text-decoration-none d-inline-block float-end">
                    Connect wallet
                  </a>
                </div>
                <p className="text-muted mb-4">Trade tokens in an instant</p>
                <div className="mb-3">
                  <label htmlFor="fromAmount" className="form-label">
                    From
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="fromAmount"
                      placeholder={0.0}
                    />
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=032"
                        alt="BNB"
                        width={20}
                        height={20}
                      />{" "}
                      USDT
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          BNB
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          ETH
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          BTC
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <i className="bi bi-arrow-down" />
                </div>
                <div className="mb-3">
                  <label htmlFor="toAmount" className="form-label">
                    To
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="toAmount"
                      placeholder={0.0}
                    />
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="./assets/img/EMU.png"
                        alt="BNB"
                        width={20}
                        height={20}
                      />{" "}
                      EMU
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          BNB
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          ETH
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          BTC
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button className="btn btn-primary purple-bg w-100">
                  Swap
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="vx_tokenomicsmain py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-10">
            <div className="vx_section-title text-center mb-60">
              <span className="text-dark-grey text-uppercase">
                <span>Tokenomics at a Glance</span>
              </span>
              <h3 className="text-white h2 text-uppercase">Tokenomics</h3>
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-lg-5">
          <div className="col-lg-6">
            <div className="vx_tokenomicsmain_images mt-4 px-5">
              <img src="./assets/img/tokenomics.svg" alt="tokenomics img" />
            </div>
          </div>
          <div className="col-lg-6 tokenomicstable mt-lg-0 mt-5">
            <h4 className="h4 text-white mb-4">Coin Details</h4>
            <ul>
              <li>
                <h5 className="text-white h6 mb-0 fw-normal">
                  Chain Network :
                </h5>
                <span className="text-dark-grey">Binance Smart Chain</span>
              </li>
              <li>
                <h5 className="text-white h6 mb-0 fw-normal">Coin Name :</h5>
                <span className="text-dark-grey">EMU COIN</span>
              </li>
              <li>
                <h5 className="text-white h6 mb-0 fw-normal">Token Ticker :</h5>
                <span className="text-dark-grey">-</span>
              </li>
              <li>
                <h5 className="text-white h6 mb-0 fw-normal">
                  Smart Contract :
                </h5>
                <a
                  href="https://polygonscan.com/token/0x7d24903b4c4c81456cf9bf66b73ce95dc6914950"
                  target="_blank"
                  rel="noreferrer"
                >
                  0x7d24..14950
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="py-lg-5 py-0 my-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center text-white mb-4">
            <small>OUR BLOG</small>
            <h2 className="text-uppercase h1 mt-1 mb-3">Metaverse News</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/post22-copyright-890x664.jpg"
                className="card-img-top"
                alt="Choose an avatar and enter the fray"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    Heroes, Hot Releases -{" "}
                  </small>
                  <span className="text-dark-grey ms-2"> Apr 15, 2022</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  Choose an avatar and enter the fray
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/post42-copyright-890x664.jpg"
                className="card-img-top"
                alt="Mafia, cartels, and FBI on a mission"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    News -{" "}
                  </small>{" "}
                  <span className="text-dark-grey ms-2">Apr 15, 2022</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  Mafia, cartels, and FBI on a secret mission
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/post23-copyright-890x664.jpg"
                className="card-img-top"
                alt="Travel through time and space via Matrix"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    Hot Releases -{" "}
                  </small>
                  <span className="text-dark-grey ms-2"> Apr 14, 2022</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  Travel through time and space via Matrix
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="big-text-parent">
        <img className="w-100" src="./assets/img/text-writen.png" />
      </div>
    </section>
  </main>
  <footer className="footer py-lg-5 py-0 text-white my-5 bg-cs-dark">
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-4">
          <h5 className="mb-4">NEWSLETTER SIGNUP</h5>
          <div className="input-group mb-3 w-50">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email Address"
            />
            <button className="btn" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                fill="#fff"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13H7v-2h10v2zm0-4H7V9h10v2z" />
              </svg>
            </button>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="privacyPolicy"
            />
            <label className="form-check-label" htmlFor="privacyPolicy">
              I agree to the{" "}
              <a href="#" className="text-white">
                Privacy Policy
              </a>
              .
            </label>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="row">
            <div className="col-md-4 mb-4">
              <h5 className="text-light mb-4">SOCIALS</h5>
              <ul className="list-unstyled">
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Facebook
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Twitter
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Dribbble
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h5 className="text-light mb-4">MENU</h5>
              <ul className="list-unstyled">
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Home
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Services
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    About Us
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Shop
                  </a>
                </li>
                <li className="mb-3 ">
                  <a className="text-unique-grey text-decoration-none" href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h5 className="text-light mb-4">SAY HELLO</h5>
              <p className="text-light">contact@emucoin.io</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-lg-4">
        <div className="col-md-6">
          <div className="elementor-text-editor elementor-clearfix">
            <a
              href="https://themerex.net/"
              className="text-white"
              target="_blank"
              rel="noopener"
            >
              ThemeRex
            </a>{" "}
            © 2024. All Rights Reserved.
          </div>
        </div>
        <div className="col-md-6 text-md-end">
          <div className="social-icons">
            <a href="#" className="fab text-white fa-facebook-f" />
            <a href="#" className="fab text-white fa-twitter ms-3" />
            <a href="#" className="fab text-white fa-dribbble ms-3" />
            <a href="#" className="fab text-white fa-instagram ms-3" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</>
  );
}

export default App;
