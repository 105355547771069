import React from 'react';
// import './App.css'; // Import your custom CSS file

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src="./assets/img/EMU.png"
              alt="Gamic"
              className="logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item mr-2">
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" href="#">
                  Pages
                </a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" href="#">
                  Blog
                </a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" href="#">
                  Shop
                </a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
